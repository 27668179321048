/**
 * @prettier
 */
import React from 'react';
import Container from '../components/Container';
import CTAButton from '../components/CTAButton';
import HeroBanner from '../components/HeroBanner';
import Page from '../components/Page';
import * as UI from '../components/UI/styles';

const ErrorPage = () => (
    <Page>
        <HeroBanner title="Not found" />

        <UI.ResponsiveSpacer size="s" sizeAtMobile="l" />

        <Container>
            <UI.LayoutContainer stack>
                <UI.LayoutItem sizeAtMobile={6 / 12}>
                    <p>Sorry, we couldn't find what you were looking for.</p>
                    <CTAButton to="/">Go home</CTAButton>
                </UI.LayoutItem>
            </UI.LayoutContainer>
        </Container>
    </Page>
);

export default ErrorPage;
